/* eslint-disable consistent-return */
/*
    Middleware to check if the user is a guest.
    Otherwise, redirect to the dashboard page.
*/
export default defineNuxtRouteMiddleware(async (from, to) => {
    const jwt = useCookie('jwt');
    const headers = useRequestHeaders();
    const headerJwt = headers?.cookie?.jwt;
    const finalJWT = headerJwt || jwt.value;
    const { query, path } = to;
    const wincodeCookie = useCookie('wincode');

    if (query.code && !path.includes('password-reset')) {
        wincodeCookie.value = query.code;
    }

    if (finalJWT) {
        isAuthenticated.value = true;
        const localePath = useLocalePath();
        const navigateToPath = wincodeCookie.value ? '/play-codes' : '/dashboard';

        return navigateTo(localePath(navigateToPath));
    }
});
